.DashingActivityContainer {
    max-width: 512px;
}

.CommentForm {
    background-color: #fff;
    border-radius: 3px;
    margin: 4px 4px 12px 0;
    position: relative;
    transition: box-shadow 85ms ease;
    box-shadow: 0 4px 8px -2px #091e4240, 0 0 0 1px #091e4214;
}
.CommentForm > .FileDrop-Container {
    width: 100%;
}

.CommentContainer {
    width: 100%;
    padding: 8px 12px;
    position: relative;
    transition-duration: 85ms;
    transition-property: padding-bottom;
    transition-timing-function: ease;
}

.CommentTextArea {
    border-radius: 3px;
    display: block;
    line-height: 20px;
    outline: none;
    transition-duration: 85ms;
    transition-property: background-color,border-color,box-shadow;
    transition-timing-function: ease;
    background: #fff!important;
    box-shadow: none;
    box-sizing: initial;
    height: 20px;
    margin: 0;
    min-height: 20px;
    padding: 0;
    resize: none;
    width: 100%;
    border: 0;
}

.CommentTextArea:focus {
    outline: none;
    border: none;
    outline-offset: 0;
    box-shadow: none;
}

.CommentActionPanel {
    margin-top: 10px;
    overflow-y: hidden;
    transition-duration: 85ms;
    transition-property: height;
    transition-timing-function: ease;
    height: 0px;
    display: flex;
    align-items: center;
}
.CommentActionPanel--show {
    height: 40px;
}

.CommentSubmit {
    font-size: 14px;
    font-weight: 400;
    justify-content: center;
    line-height: 20px;
    padding: 6px 12px;

    background-color: rgba(79,70,229,1);
    color: white;
}

.CommentSubmit:disabled {
    background-color: #091e420a;
    box-shadow: none;
    color: #a5adba;
    cursor: not-allowed;
}

.CommentPanelButton {
    justify-content: center;
    margin-left: 15px;
    line-height: 20px;
    padding: 6px 12px;
}
.CommentPanelButton:hover {
    background-color: #f1f1f1;
}

.CommentPanelButton > svg {
    height: 20px;
    width: 20px;
}

.CommentMarkdownContainer {
    display: block;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 1px 2px -1px #091e4240, 0 0 0 1px #091e4214;
    box-sizing: border-box;
    clear: both;
    margin: 4px 2px 4px 0;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    word-break: break-word;
    padding: 8px 12px;
}

.EventMarkdownContainer {
    display: block;
    clear: both;
    margin: 2px 2px 2px 0;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    word-break: break-word;
    padding: 4px 12px;
    font-size: 12px;
    line-height: 1.5;
    color: #6d6e6f;
}

.EventMarkdownContainer--user {
    font-weight: bold;
}

.EventMarkdownContainer--timestamp {
    font-style: italic;
}

.FileDrop-Container {
    display: inline-block;
    position: relative;
}

.FileDrop-Overlay {
    border: dashed grey 3px;
    background-color: rgba(255,255,255,.8);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2001;
}

.FileDrop-Hint {
    color: grey;
    font-size: 1.4rem;
}

.CommentMarkdownContainer .DkMarkdown {
    font-size: 1rem;
    line-height: 1.75;
    color: #374151;
}

/*.EventMarkdownContainer .DkMarkdown {*/
/*    font-size: 12px;*/
/*    line-height: 1.5;*/
/*    color: #6d6e6f;*/
/*}*/

/* MARKDOWN */
.DkMarkdown {
    max-width: 65ch;
}

.DkMarkdown [class~="lead"] {
    color: #4b5563;
    font-size: 1.25em;
    line-height: 1.6;
    margin-top: 1.2em;
    margin-bottom: 1.2em;
}

.DkMarkdown a {
    color: #111827;
    text-decoration: underline;
    font-weight: 500;
}

.DkMarkdown strong {
    color: #111827;
    font-weight: 600;
}

.DkMarkdown ol[type="A"] {
    --list-counter-style: upper-alpha;
}

.DkMarkdown ol[type="a"] {
    --list-counter-style: lower-alpha;
}

.DkMarkdown ol[type="A" s] {
    --list-counter-style: upper-alpha;
}

.DkMarkdown ol[type="a" s] {
    --list-counter-style: lower-alpha;
}

.DkMarkdown ol[type="I"] {
    --list-counter-style: upper-roman;
}

.DkMarkdown ol[type="i"] {
    --list-counter-style: lower-roman;
}

.DkMarkdown ol[type="I" s] {
    --list-counter-style: upper-roman;
}

.DkMarkdown ol[type="i" s] {
    --list-counter-style: lower-roman;
}

.DkMarkdown ol[type="1"] {
    --list-counter-style: decimal;
}

.DkMarkdown ol > li {
    position: relative;
    padding-left: 1.75em;
}

.DkMarkdown ol > li::before {
    content: counter(list-item, var(--list-counter-style, decimal)) ".";
    position: absolute;
    font-weight: 400;
    color: #6b7280;
    left: 0;
}

.DkMarkdown ul > li {
    position: relative;
    padding-left: 1.75em;
}

.DkMarkdown ul > li::before {
    content: "";
    position: absolute;
    background-color: #d1d5db;
    border-radius: 50%;
    width: 0.375em;
    height: 0.375em;
    top: calc(0.875em - 0.1875em);
    left: 0.25em;
}

.DkMarkdown hr {
    border-color: #e5e7eb;
    border-top-width: 1px;
    margin-top: 3em;
    margin-bottom: 3em;
}

.DkMarkdown blockquote {
    font-weight: 500;
    font-style: italic;
    color: #111827;
    border-left-width: 0.25rem;
    border-left-color: #e5e7eb;
    quotes: "\201C""\201D""\2018""\2019";
    margin-top: 1.6em;
    margin-bottom: 1.6em;
    padding-left: 1em;
}

.DkMarkdown blockquote p:first-of-type::before {
    content: open-quote;
}

.DkMarkdown blockquote p:last-of-type::after {
    content: close-quote;
}

.DkMarkdown h1 {
    color: #111827;
    font-weight: 800;
    font-size: 2.25em;
    margin-top: 0;
    margin-bottom: 0.8888889em;
    line-height: 1.1111111;
}

.DkMarkdown h2 {
    color: #111827;
    font-weight: 700;
    font-size: 1.5em;
    margin-top: 2em;
    margin-bottom: 1em;
    line-height: 1.3333333;
}

.DkMarkdown h3 {
    color: #111827;
    font-weight: 600;
    font-size: 1.25em;
    margin-top: 1.6em;
    margin-bottom: 0.6em;
    line-height: 1.6;
}

.DkMarkdown h4 {
    color: #111827;
    font-weight: 600;
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    line-height: 1.5;
}

.DkMarkdown figure figcaption {
    color: #6b7280;
    font-size: 0.875em;
    line-height: 1.4285714;
    margin-top: 0.8571429em;
}

.DkMarkdown code {
    color: #111827;
    font-weight: 600;
    font-size: 0.875em;
}

.DkMarkdown code::before {
    content: "`";
}

.DkMarkdown code::after {
    content: "`";
}

.DkMarkdown a code {
    color: #111827;
}

.DkMarkdown pre {
    color: #e5e7eb;
    background-color: #1f2937;
    overflow-x: auto;
    font-size: 0.875em;
    line-height: 1.7142857;
    margin-top: 1.7142857em;
    margin-bottom: 1.7142857em;
    border-radius: 0.375rem;
    padding-top: 0.8571429em;
    padding-right: 1.1428571em;
    padding-bottom: 0.8571429em;
    padding-left: 1.1428571em;
}

.DkMarkdown pre code {
    background-color: transparent;
    border-width: 0;
    border-radius: 0;
    padding: 0;
    font-weight: 400;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
}

.DkMarkdown pre code::before {
    content: none;
}

.DkMarkdown pre code::after {
    content: none;
}

.DkMarkdown p {
    margin-top: 1.25em;
    margin-bottom: 1.25em;
}

.DkMarkdown img {
    margin-top: 2em;
    margin-bottom: 2em;
}

.DkMarkdown video {
    margin-top: 2em;
    margin-bottom: 2em;
}

.DkMarkdown figure {
    margin-top: 2em;
    margin-bottom: 2em;
}

.DkMarkdown figure > * {
    margin-top: 0;
    margin-bottom: 0;
}

.DkMarkdown h2 code {
    font-size: 0.875em;
}

.DkMarkdown h3 code {
    font-size: 0.9em;
}

.DkMarkdown ol {
    margin-top: 1.25em;
    margin-bottom: 1.25em;
}

.DkMarkdown ul {
    margin-top: 1.25em;
    margin-bottom: 1.25em;
}

.DkMarkdown li {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

.DkMarkdown > ul > li p {
    margin-top: 0.75em;
    margin-bottom: 0.75em;
}

.DkMarkdown > ul > li > *:first-child {
    margin-top: 1.25em;
}

.DkMarkdown > ul > li > *:last-child {
    margin-bottom: 1.25em;
}

.DkMarkdown > ol > li > *:first-child {
    margin-top: 1.25em;
}

.DkMarkdown > ol > li > *:last-child {
    margin-bottom: 1.25em;
}

.DkMarkdown ul ul, .DkMarkdown ul ol, .DkMarkdown ol ul, .DkMarkdown ol ol {
    margin-top: 0.75em;
    margin-bottom: 0.75em;
}

.DkMarkdown hr + * {
    margin-top: 0;
}

.DkMarkdown h2 + * {
    margin-top: 0;
}

.DkMarkdown h3 + * {
    margin-top: 0;
}

.DkMarkdown h4 + * {
    margin-top: 0;
}

.DkMarkdown > :first-child {
    margin-top: 0;
}

.DkMarkdown > :last-child {
    margin-bottom: 0;
}
