[data-whatintent='keyboard'] * {
    outline-color: orange;
    outline-offset: 4px;
}

[data-whatintent='mouse'] .outline-none-mouse:focus {
    outline: none;
}

input[type='number'] {
    -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
